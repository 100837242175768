<template>
  <div class="editpwd">
    <Form ref="form" :model="data" :rules="rules" :label-width="100">
      <FormItem label="当前密码" prop="user_pwd">
        <Input v-model="data.user_pwd" maxlength="32" type="password"></Input>
      </FormItem>
      <FormItem label="新密码" prop="new_pwd">
        <Input v-model="data.new_pwd" maxlength="32" type="password" placeholder="长度限制：8~32位"></Input>
      </FormItem>
      <FormItem label="当前口令" prop="user_word">
        <Input v-model="data.user_word" maxlength="32" type="password"></Input>
      </FormItem>
      <FormItem label="新口令" prop="new_word">
        <Input v-model="data.new_word" maxlength="32" type="password" placeholder="长度限制：8~32位"></Input>
      </FormItem>
      <FormItem label="">
        <Button type="primary" @click="subsave">保存修改</Button>
      </FormItem>
    </Form>
  </div>
</template>

<script>
import md5 from 'js-md5';
export default {
  name: 'Editpwd',
  data() {
    return {
      data:{
        user_pwd:'',
        new_pwd:'',
        user_word:'',
        new_word:''
      },
      rules:{
        user_pwd:[
          {type:'string',required:true,message:'请填写当前密码',trigger:'blur'},
          {type:'string',pattern:/\S{6,32}$/,message: '当前密码不正确',trigger:'blur'},
        ],
        new_pwd:[
          {type:'string',required:true,message:'请填写新密码',trigger:'blur'},
          {type:'string',pattern:/\S{8,32}$/,message: '新密码长度必须为8~32位',trigger:'blur'},
        ],
        user_word:[
          {type:'string',required:true,message:'请填写当前口令',trigger:'blur'},
          {type:'string',pattern:/\S{6,32}$/,message: '当前口令不正确',trigger:'blur'},
        ],
        new_word:[
          {type:'string',required:true,message:'请填写新口令',trigger:'blur'},
          {type:'string',pattern:/\S{8,32}$/,message: '新口令长度必须为8~32位',trigger:'blur'},
        ]
      }
    }
  },
  methods:{
    subsave:function (){
      var _this=this,data=JSON.parse(JSON.stringify(_this.data));
      data.user_pwd=md5(data.user_pwd);
      data.user_word=md5(data.user_word);
      this.$refs['form'].validate(function (checkres){
        if(checkres){
          _this.requestApi('/adm/eselfpwd.html',data).then(function (res){
            if(res.status==1){
              _this.data={user_pwd:'',new_pwd:'',user_word:'',new_word:''}
              _this.tipSuccess(res.msg);
            }else{
              _this.alertError(res.msg);
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="less">
@import "~@/assets/css/login/editpwd";
</style>